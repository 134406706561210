export default function IconMatterport({ color }: { color?: boolean }): JSX.Element {
  return (
    <svg viewBox="208.0856 180.0227 35.9 35.94" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
      <g transform="matrix(1, 0, 0, 1, 204.40557098388675, 173.11268806457522)">
        <path
          d="M34.44,6.91L21.62,10.5L8.81,6.91L3.68,8.35v33.06l5.13,1.44l12.82-3.59l12.82,3.59l5.13-1.44V8.35 L34.44,6.91z M29.31,31.58l-7.69-2.15l-7.69,2.15v-13.4l7.69,2.15l7.69-2.15V31.58z"
          fill={color ? '#ff3158' : 'currentColor'}
        />
        <g>
          <polygon
            points="21.62,13.37 21.62,20.33 8.81,16.74 8.81,42.84 3.68,41.41 3.68,8.35 &#9;&#9;&#9;"
            fill={color ? '#c00033' : 'currentColor'}
          />
          <polygon
            points="29.31,34.45 21.62,32.29 21.62,39.25 34.44,42.84 34.44,16.74 29.31,18.18 &#9;&#9;&#9;"
            fill={color ? '#c00033' : 'currentColor'}
          />
        </g>
        <g>
          <polygon
            points="34.44,6.91 39.56,8.35 21.62,13.37 3.68,8.35 8.81,6.91 21.62,10.5 &#9;&#9;&#9;"
            fill={color ? '#ff7787' : 'currentColor'}
          />
          <polygon
            points="21.62,29.42 29.31,31.58 29.31,34.45 21.62,32.29 13.93,34.45 13.93,31.58 &#9;&#9;&#9;"
            fill={color ? '#ff7787' : 'currentColor'}
          />
        </g>
      </g>
    </svg>
  );
}

import { Deliverable } from '~common/model/Deliverable';
import Modal from '~/components/interactive/Modal';
import DeliverableRender from '~/components/deliverable/DeliverableRender';
import useKeyboardCapture from '~/components/hooks/useKeyboardCapture';

export default function DeliverableLightbox({
  show,
  onClose,
  deliverable,
}: {
  deliverable: Deliverable;
  show: boolean;
  onClose: () => void;
}): JSX.Element {
  useKeyboardCapture({
    enabled: show,
    onEscape() {
      onClose();
    },
  });

  return (
    <Modal show={show} onClose={onClose}>
      <div
        className="w-screen h-screen p-4 sm:p-16"
        onClick={(e) => {
          if ('tagName' in e.target && e.target.tagName !== 'VIDEO') {
            // TODO: smell
            onClose();
          }
        }}
      >
        {show && <DeliverableRender data={deliverable.data} file={deliverable.file} />}
      </div>
    </Modal>
  );
}

import ResizedThumbnail from '~/components/cdn/ResizedThumbnail';
import { Deliverable, DeliverableType } from '~common/model/Deliverable';
import { ReactNode, useState } from 'react';
import DeliverableIcon from '~/components/deliverable/DeliverableIcon';
import IconVisible from '~/components/icons/streamline/line/IconVisible';
import IconEdit from '~/components/icons/streamline/line/IconEdit';
import IconDelete from '~/components/icons/streamline/line/IconDelete';
import classNames from 'classnames';
import DeliverableLightbox from '~/components/deliverable/DeliverableLightbox';
import IconDownload from '~/components/icons/streamline/line/IconDownload';
import Tooltip from '~/components/interactive/Tooltip';
import InputCheckbox from '~/components/input/InputCheckbox';

export function DeliverableThumbnailGrid(props: { children: React.ReactNode }): JSX.Element {
  return <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-4">{props.children}</div>;
}

export default function DeliverableThumbnail({
  deliverable,
  icon,
  lightbox,
  checked,
  onView,
  onEdit,
  onDownload,
  onDelete,
  onCheck,
  height,
}: {
  deliverable: Deliverable;
  lightbox?: boolean;
  icon?: boolean;
  checked?: boolean;
  height?: number;
  onDownload?: () => void;
  onView?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onCheck?: (checked: boolean) => void;
}): JSX.Element {
  const [open, setOpen] = useState(false);
  let image: ReactNode | undefined;

  if (deliverable.file?.image) {
    image = (
      <ResizedThumbnail
        file={deliverable.file.image}
        fit={deliverable.data.type !== DeliverableType.PHOTO && deliverable.data.type !== DeliverableType.AERIAL}
        round
      />
    );
  } else if (deliverable.data.type === DeliverableType.MATTERPORT) {
    let id = '';

    try {
      const url = new URL(deliverable.data.url);
      const m = url.searchParams.get('m');

      if (m) {
        id = m;
      }
    } catch (ex) {
      // TODO: error log
    }

    if (id) {
      const src = `https://my.matterport.com/api/v1/player/models/${id}/thumb`;

      image = <img src={src} className="object-cover rounded-theme overflow-hidden w-full h-full" />;
    }
  }

  const style = (className: string, hover = true): string =>
    classNames('size-5 absolute p-1 rounded-full ring-1 ring-gray-500 z-10', className, {
      'transition hover:scale-125 cursor-pointer': hover,
      'bg-white text-gray-500': deliverable.data.type !== DeliverableType.FLOORPLAN,
      'bg-black text-white': deliverable.data.type == DeliverableType.FLOORPLAN,
      'hover:bg-black hover:text-white': deliverable.data.type !== DeliverableType.FLOORPLAN && hover,
      ' hover:text-gray-500 hover:bg-white': deliverable.data.type == DeliverableType.FLOORPLAN && hover,
    });

  const onOpen = (): void => {
    if (onView) {
      onView();
    }

    if (lightbox) {
      setOpen(true);
    }
  };

  const thumbnail = (
    <div className="relative">
      <div
        onClick={() => onCheck?.(!checked)}
        style={{ height }}
        className={classNames('*:w-full *:h-full z-0', {
          sepia: checked,
          'cursor-pointer': Boolean(onCheck),
          'h-[128px]': !height,
        })}
      >
        {image}
      </div>
      {icon && (
        <div className={style('top-2 left-2', false)}>
          <Tooltip text="Deliverable Type">
            <DeliverableIcon type={deliverable.data.type} />
          </Tooltip>
        </div>
      )}
      {onDownload && (
        <div className={style('top-2 left-2')} onClick={onDownload}>
          <Tooltip text="Download Deliverable">
            <IconDownload />
          </Tooltip>
        </div>
      )}
      {onCheck && (
        <div className="absolute top-2 left-2 z-10">
          <Tooltip text="Select Deliverable">
            <InputCheckbox checked={checked} onChange={onCheck} />
          </Tooltip>
        </div>
      )}
      {(onView || lightbox) && (
        <div className={style('bottom-2 left-2')} onClick={onOpen}>
          <Tooltip text="View Deliverable">
            <IconVisible />
          </Tooltip>
        </div>
      )}
      {onEdit && (
        <div className={style('bottom-2 right-2')} onClick={onEdit}>
          <Tooltip text="Edit Deliverable">
            <IconEdit />
          </Tooltip>
        </div>
      )}
      {onDelete && (
        <div className={style('top-2 right-2')} onClick={onDelete}>
          <Tooltip text="Delete Deliverable">
            <IconDelete />
          </Tooltip>
        </div>
      )}
    </div>
  );

  if (lightbox) {
    return (
      <>
        {thumbnail}
        <DeliverableLightbox deliverable={deliverable} show={open} onClose={() => setOpen(false)} />
      </>
    );
  }

  return thumbnail;
}
